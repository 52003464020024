<!-- RECIBO -->
<div class="content" style="page-break-after:always;">
    <div id="data" class="row w-100 m-0 h-100 justify-content-center position-relative" style="height: 267mm">
        <div class="col-auto"
            style="transform: scale(0.75, 0.6); height: 267mm; cursor: pointer; position: relative; width: 210mm;"
            (click)="onPrint()">


            <div class="container" style=" width: 210mm; position: absolute; top: -24rem;">
                <!-- HEADER -->
                <div class="row d-flex justify-content-between">
                    <!-- LOGO E TEXTO -->
                    <div class="col-3 mx-auto">
                        <img [src]="invoiceData?.main_company.logo" style="width: 100% !important">
                        <p class="mt-3" style="margin-bottom: 0 !important;">{{ invoiceData?.main_company.name }} </p>
                        <p class="" style="margin-bottom: 0 !important;">{{
                            invoiceData?.main_company.city.state.country.name }} - {{
                            invoiceData?.main_company.city.state.name }} - {{ invoiceData?.main_company.city.name }} -
                            {{ invoiceData?.main_company.address }} - {{ invoiceData?.main_company.zipcode }}</p>
                        <p class="" style="margin-bottom: 0 !important;">
                            {{ invoiceData?.main_company.telephone }}
                        </p>
                    </div>
                    <!-- RECIBO -->
                    <div class="col-3 mx-auto">
                        <h2 class="text-center"><strong>RECIBO</strong></h2>
                        <p class="text-center">
                            {{  invoiceData?.user.people.first_name }} {{  invoiceData?.user.people.last_name }}
                        </p>
                    </div>
                    <!-- NUMERO DO RECIBO -->
                    <div class="col-3 mx-auto">
                        <h2 class="right"><strong>
                                {{
                                invoiceData?.pin
                                }}
                            </strong></h2>
                    </div>
                </div>
                <!-- SUB HEADER -->
                <div class="d-flex justify-content-center">
                    <!-- DADOS DO RECIBO -->
                    <div class="col-4">
                        <p class="text-center">
                            {{
                            invoiceData?.company.name
                            }}
                        </p>
                        <p class="text-center"> {{
                            invoiceData?.company.address
                            }}
                            {{
                            invoiceData?.company.telephone
                            }}
                            {{
                            invoiceData?.company.city.name
                            }}
                            {{
                            invoiceData?.company.city.state.name
                            }}
                            {{
                            invoiceData?.company.city.state.country.name
                            }}</p>
                        <p class="text-center"><strong>Customer service {{ invoiceData?.company.telephone }}</strong>
                        </p>
                    </div>

                </div>

                <!-- BODY -->
                <div class="row">
                    <!-- DADOS DE BENEFICIARIO -->
                    <div class="col-6" style="border-color: black; border-width: 3px; border-style: solid;">
                        <!-- NOME E TELEFONE -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5>Beneficiário</h5>
                                <p>
                                    {{
                                    invoiceData?.userBeneficiary.people?.first_name
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.second_name
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.third_name
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.last_name
                                    }}
                                    (
                                    {{
                                    invoiceData?.userBeneficiary.id
                                    }}
                                    ) </p>
                            </div>
                            <div>
                                <h5>Telefone</h5>
                                <p>
                                    {{
                                    invoiceData?.userBeneficiary.people?.phone
                                    }}
                                </p>
                            </div>
                        </div>
                        <!-- ENDERECO -->
                        <div>
                            <h5>Endereço</h5>
                            <p>
                                    {{
                                    invoiceData?.userBeneficiary.people?.address[0]?.street
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.address[0]?.complement
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.address[0]?.number
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.address[0]?.district
                                    }}
                            </p>
                        </div>
                        <!-- CIDADE - ESTADO - PAIS -->
                        <div>
                            <h5>Cidade - Estado - País</h5>
                            <p>{{
                                invoiceData?.userBeneficiary.people?.address[0]?.city?.name
                                }} -
                                {{
                                invoiceData?.userBeneficiary.people?.address[0]?.city?.state?.name
                                }}
                                -
                                {{
                                invoiceData?.userBeneficiary.people?.address[0]?.city?.state?.country?.name
                                }}</p>
                        </div>
                        <!-- BANCO E LOJA -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5>Banco</h5>
                                <p>
                                    {{
                                    invoiceData?.userBeneficiary.bank_account?.bank_name
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.bank_account?.account_number
                                    }}
                                </p>
                            </div>
                            <!-- <div>
                                <h5>Loja</h5>
                                <p></p>
                            </div> -->
                        </div>
                    </div>
                    <!-- DADOS DE REMETENTE -->
                    <div class="col-6" style="border: 3px solid black ; border-left: 0;">
                        <!-- NOME E TELEFONE -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5>Remetente</h5>
                                <p>{{
                                    invoiceData?.userSender.people?.first_name
                                    }}
                                    {{
                                    invoiceData?.userSender.people?.second_name
                                    }}
                                    {{
                                    invoiceData?.userSender.people?.third_name
                                    }}
                                    {{
                                    invoiceData?.userSender.people?.last_name
                                    }}
                                    (
                                    {{
                                    invoiceData?.userSender.id
                                    }}
                                    )</p>
                            </div>
                            <div>
                                <h5>Telefone</h5>
                                <p>
                                    {{
                                    invoiceData?.userSender.people?.phone
                                    }}
                                </p>
                            </div>
                        </div>
                        <!-- ENDERECO -->
                        <div>
                            <h5>Endereço</h5>
                            <p>

                                {{
                                invoiceData?.userSender.people?.address[0]?.street
                                }}
                                {{
                                invoiceData?.userSender.people?.address[0]?.complement
                                }}
                                {{
                                invoiceData?.userSender.people?.address[0]?.number
                                }}
                                {{
                                invoiceData?.userSender.people?.address[0]?.district
                                }}
                            </p>
                        </div>
                        <!-- CIDADE - ESTADO - PAIS -->
                        <div>
                            <h5>Cidade - Estado - País</h5>
                            <p>
                                <!-- {{
                                invoiceData?.userSender.people?.address[0]?.zip_code
                                }} -->
                                {{
                                invoiceData?.userSender.people?.address[0]?.city.name
                                }} -
                                {{
                                invoiceData?.userSender.people?.address[0]?.city.state.name
                                }}
                                -
                                {{
                                invoiceData?.userSender.people?.address[0]?.city.state.country.name
                                }}
                            </p>
                        </div>
                        <!-- BANCO E LOJA -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5>Banco</h5>
                                <p>
                                    {{
                                        invoiceData?.userSender.bank_account?.bank_name
                                        }}
                                        {{
                                            invoiceData?.userSender.bank_account?.account_number
                                            }}
                                </p>
                            </div>
                            <!-- <div>
                                <h5>Loja</h5>
                                <p></p>
                            </div> -->
                        </div>
                    </div>
                    <!-- DETALHES DE ORDEM -->
                    <div class="col-5" style="border: 3px solid black ; border-top: 0;">
                        <div class="row p-0">
                            <div class="d-flex justify-content-between col-12">
                                <h5>Data e Hora</h5>
                                <p>
                                    {{
                                    invoiceData?.date_event | date: 'dd/MM/yyyy'
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Número do Recibo</h5>
                                <p>
                                    {{
                                    invoiceData?.reference
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>PIN</h5>
                                <p>
                                    {{
                                    invoiceData?.pin
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Entrega</h5>
                                <p>
                                    {{
                                    invoiceData?.delivery_method
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Montante</h5>
                                <p>
                                    {{
                                    invoiceData?.amount
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Tarifa</h5>
                                <p> {{
                                    invoiceData?.fee
                                    }} </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Outros</h5>
                                <p>{{
                                    invoiceData?.other
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>IOF</h5>
                                <p>{{
                                    invoiceData?.iof
                                    }} </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Total</h5>
                                <p>{{
                                    invoiceData?.total
                                    }} </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Taxa de Câmbio</h5>
                                <p>{{
                                    invoiceData?.spread
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Valor a Receber</h5>
                                <p>{{
                                    invoiceData?.value_send
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Tempo de Entrega</h5>
                                <p>{{
                                    invoiceData?.time_delivery
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Equivalente (USD)</h5>
                                <p>{{
                                    invoiceData?.equivalent
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Taxa de câmbio inversa</h5>
                                <p>{{
                                    invoiceData?.spread_convert
                                    }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- ASSINATURAS -->
                    <div class="col-7" style="border: 3px solid black ; border-top: 0; border-left: 0; padding: 0;">
                        <h5 class="p-2" style="margin-bottom: 2rem;">Assinatura do Remetente</h5>
                        <div class="bar"></div>
                        <h5 class="p-2" style="margin-bottom: 2rem;">Assinatura do Operador ({{  invoiceData?.user.people.first_name }} {{  invoiceData?.user.people.last_name }})</h5>
                        <div class="bar"></div>
                        <p class="p-2">
                            {{invoiceData?.compliance}}

                        </p>
                    </div>
                </div>
            </div>

            <!-- MARCADOR PARA SEGUNDA VIA -->
            <div class="row w-100 m-0 justify-content-center" style="position: absolute; top: 40.3rem;">
                <div class="col-auto">
                    <div class="dotted mb-5" style="width: 210mm; margin-top: 5rem"></div>
                </div>
            </div>


            <!-- RECIBO -->
            <div class="container p-3" style="width: 210mm; top: 45rem; position: absolute">
                <!-- HEADER -->
                <div class="row d-flex justify-content-between">
                    <!-- LOGO E TEXTO -->
                    <div class="col-3 mx-auto">
                        <img [src]="invoiceData?.main_company.logo" style="width: 100% !important">
                        <p class="mt-3" style="margin-bottom: 0 !important;">{{ invoiceData?.main_company.name }} </p>
                        <p class="" style="margin-bottom: 0 !important;">{{
                            invoiceData?.main_company.city.state.country.name }} - {{
                            invoiceData?.main_company.city.state.name }} - {{ invoiceData?.main_company.city.name }} -
                            {{ invoiceData?.main_company.address }} - {{ invoiceData?.main_company.zipcode }}</p>
                        <p class="" style="margin-bottom: 0 !important;">
                            {{ invoiceData?.main_company.telephone }}
                        </p>
                    </div>
                    <!-- RECIBO -->
                    <div class="col-3 mx-auto">
                        <h2 class="text-center"><strong>RECIBO</strong></h2>
                        <p class="text-center">
                            {{  invoiceData?.user.people.first_name }} {{  invoiceData?.user.people.last_name }}
                        </p>
                    </div>
                    <!-- NUMERO DO RECIBO -->
                    <div class="col-3 mx-auto">
                        <h2 class="right"><strong>
                                {{
                                invoiceData?.pin
                                }}
                            </strong></h2>
                    </div>
                </div>
                <!-- SUB HEADER -->
                <div class="d-flex justify-content-center">
                    <!-- DADOS DO RECIBO -->
                    <div class="col-4">
                        <p class="text-center">
                            {{
                            invoiceData?.company.name
                            }}
                        </p>
                        <p class="text-center"> {{
                            invoiceData?.company.address
                            }}
                            {{
                            invoiceData?.company.telephone
                            }}
                            {{
                            invoiceData?.company.city.name
                            }}
                            {{
                            invoiceData?.company.city.state.name
                            }}
                            {{
                            invoiceData?.company.city.state.country.name
                            }}</p>
                        <p class="text-center"><strong>Customer service {{ invoiceData?.company.telephone }}</strong>
                        </p>
                    </div>

                </div>

                <!-- BODY -->
                <div class="row p-3">
                    <!-- DADOS DE BENEFICIARIO -->
                    <div class="col-6" style="border-color: black; border-width: 3px; border-style: solid;">
                        <!-- NOME E TELEFONE -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5>Beneficiário</h5>
                                <p>
                                    {{
                                    invoiceData?.userBeneficiary.people?.first_name
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.second_name
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.third_name
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.last_name
                                    }}
                                    (
                                    {{
                                    invoiceData?.userBeneficiary.id
                                    }}
                                    ) </p>
                            </div>
                            <div>
                                <h5>Telefone</h5>
                                <p>
                                    {{
                                    invoiceData?.userBeneficiary.people?.phone
                                    }}
                                </p>
                            </div>
                        </div>
                        <!-- ENDERECO -->
                        <div>
                            <h5>Endereço</h5>
                            <p>
                                    {{
                                    invoiceData?.userBeneficiary.people?.address[0]?.street
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.address[0]?.complement
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.address[0]?.number
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.people?.address[0]?.district
                                    }}
                            </p>
                        </div>
                        <!-- CIDADE - ESTADO - PAIS -->
                        <div>
                            <h5>Cidade - Estado - País</h5>
                            <p>{{
                                invoiceData?.userBeneficiary.people?.address[0]?.city?.name
                                }} -
                                {{
                                invoiceData?.userBeneficiary.people?.address[0]?.city?.state?.name
                                }}
                                -
                                {{
                                invoiceData?.userBeneficiary.people?.address[0]?.city?.state?.country?.name
                                }}</p>
                        </div>
                        <!-- BANCO E LOJA -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5>Banco</h5>
                                <p>
                                    {{
                                    invoiceData?.userBeneficiary.bank_account?.bank_name
                                    }}
                                    {{
                                    invoiceData?.userBeneficiary.bank_account?.account_number
                                    }}
                                </p>
                            </div>
                            <!-- <div>
                                <h5>Loja</h5>
                                <p></p>
                            </div> -->
                        </div>
                    </div>
                    <!-- DADOS DE REMETENTE -->
                    <div class="col-6" style="border: 3px solid black ; border-left: 0;">
                        <!-- NOME E TELEFONE -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5>Remetente</h5>
                                <p>{{
                                    invoiceData?.userSender.people?.first_name
                                    }}
                                    {{
                                    invoiceData?.userSender.people?.second_name
                                    }}
                                    {{
                                    invoiceData?.userSender.people?.third_name
                                    }}
                                    {{
                                    invoiceData?.userSender.people?.last_name
                                    }}
                                    (
                                    {{
                                    invoiceData?.userSender.id
                                    }}
                                    )</p>
                            </div>
                            <div>
                                <h5>Telefone</h5>
                                <p>
                                    {{
                                        invoiceData?.userSender.people?.phone == undefined || invoiceData?.userSender.people?.phone == null ? '' : invoiceData?.userSender.people?.phone
                                    }}
                                </p>
                            </div>
                        </div>
                        <!-- ENDERECO -->
                        <div>
                            <h5>Endereço</h5>
                            <p>

                                {{
                                invoiceData?.userSender.people?.address[0]?.street
                                }}
                                {{
                                invoiceData?.userSender.people?.address[0]?.complement
                                }}
                                {{
                                invoiceData?.userSender.people?.address[0]?.number
                                }}
                                {{
                                invoiceData?.userSender.people?.address[0]?.district
                                }}
                            </p>
                        </div>
                        <!-- CIDADE - ESTADO - PAIS -->
                        <div>
                            <h5>Cidade - Estado - País</h5>
                            <p>
                                <!-- {{
                                invoiceData?.userSender.people?.address[0]?.zip_code
                                }} -->
                                {{
                                invoiceData?.userSender.people?.address[0]?.city.name
                                }} -
                                {{
                                invoiceData?.userSender.people?.address[0]?.city.state.name
                                }}
                                -
                                {{
                                invoiceData?.userSender.people?.address[0]?.city.state.country.name
                                }}
                            </p>
                        </div>
                        <!-- BANCO E LOJA -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <h5>Banco</h5>
                                <p>
                                    {{
                                        invoiceData?.userSender.bank_account?.bank_name
                                        }}
                                        {{
                                            invoiceData?.userSender.bank_account?.account_number
                                            }}
                                </p>
                            </div>
                            <!-- <div>
                                <h5>Loja</h5>
                                <p></p>
                            </div> -->
                        </div>
                    </div>
                    <!-- DETALHES DE ORDEM -->
                    <div class="col-6" style="border: 3px solid black ; border-top: 0;">
                        <div class="row p-0">
                            <div class="d-flex justify-content-between col-12">
                                <h5>Data e Hora</h5>
                                <p>
                                    {{
                                    invoiceData?.date_event | date: 'dd/MM/yyyy'
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Número do Recibo</h5>
                                <p>
                                    {{
                                    invoiceData?.reference
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>PIN</h5>
                                <p>
                                    {{
                                    invoiceData?.pin
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Entrega</h5>
                                <p>
                                    {{
                                    invoiceData?.delivery_method
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Montante</h5>
                                <p>
                                    {{
                                    invoiceData?.amount
                                    }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Tarifa</h5>
                                <p> {{
                                    invoiceData?.fee
                                    }} </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Outros</h5>
                                <p>{{
                                    invoiceData?.other
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>IOF</h5>
                                <p>{{
                                    invoiceData?.iof
                                    }} </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Total</h5>
                                <p>{{
                                    invoiceData?.total
                                    }} </p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Taxa de Câmbio</h5>
                                <p>{{
                                    invoiceData?.spread
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Valor a Receber</h5>
                                <p>{{
                                    invoiceData?.value_send
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Tempo de Entrega</h5>
                                <p>{{
                                    invoiceData?.time_delivery
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Equivalente (USD)</h5>
                                <p>{{
                                    invoiceData?.equivalent
                                    }}</p>
                            </div>
                            <div class="d-flex justify-content-between col-12">
                                <h5>Taxa de câmbio inversa</h5>
                                <p>{{
                                    invoiceData?.spread_convert
                                    }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- ASSINATURAS -->
                    <div class="col-6" style="border: 3px solid black ; border-top: 0; border-left: 0; padding: 0;">
                        <h5 class="p-2" style="margin-bottom: 6rem;">Assinatura do Remetente</h5>
                        <div class="bar"></div>
                        <h5 class="p-2" style="margin-bottom: 7rem;">Assinatura do Operador ({{  invoiceData?.user.people.first_name }} {{  invoiceData?.user.people.last_name }})</h5>
                        <div class="bar"></div>
                        
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>