import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { apiURL } from '../_helper/url';
import { AppConfig } from './_interface/app';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  // apiURL: any = localStorage.getItem("urlData"); //URL DA API
  apiURL: any = localStorage.getItem("urlData"); //URL DA API
  public urlData = new BehaviorSubject<any>(localStorage.getItem("urlData"));

  constructor(private http: HttpClient) {
  }

  public setPath(path: any): void {
    localStorage.setItem("urlData", path);
    this.urlData.next(path);
  }

  public getPath(): Observable<any> {
    return this.urlData.asObservable();
  }

  getAppInfo(): Observable<AppConfig> {
    let api_url: string;
    this.getPath().subscribe(res => {
      api_url = res;
    })
    return this.http.get<AppConfig>(`${api_url}/systems/info`)
  }

  getAppInfoPromisse(): Promise<any> {
    let api_url: string;
    this.getPath().subscribe(res => {
      api_url = res;
    })
    return this.http.get<AppConfig>(`${api_url}/systems/info`).toPromise();
  }

}
