import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiURL: any = localStorage.getItem("urlData"); //URL DA API
  public userDataSubject: BehaviorSubject<any>;
  public userData: Observable<any>;

  constructor(private http: HttpClient) {
    this.userDataSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
    this.userData = this.userDataSubject.asObservable();

  }

  public updateUserData(value: any) {
    this.userDataSubject.next(value);
    localStorage.setItem('user', JSON.stringify(value));

  }

  //---------------------------------------------------------------
  //Logar Usuario
  login(username: string, password: string) {
    return this.http.post<any>(`${this.apiURL}/auth/login`, { username, password })
      .pipe(map(data => {
        if (data.success) {
          localStorage.setItem('currentUser', JSON.stringify(data.data));
          localStorage.setItem('token', data?.data?.access_token);
        }
        return data;
      }));
  }//fim login

  //---------------------------------------------------------------
  //Registrar Usuario
  register(name: string, password: string, email: string) {
    return this.http.post<any>(`${this.apiURL}/admin/register`, { name: name, password: password, email: email })
  }//fim register


  //---------------------------------------------------------------
  //Obter Usuario
  getUser() {
    return this.http.get<any>(`${this.apiURL}/users`)
  }

  public userAsync(): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      this.http.get<any>(`${this.apiURL}/users`).pipe(take(1)).subscribe(res => {
        if (res?.success) {
          localStorage.setItem('user', JSON.stringify(res.data));
          this.updateUserData(res?.data);
          return resolve(res);
        }
      },
        err => {
          return reject();
        });
    });
  }

  //---------------------------------------------------------------
  //Esqueci minha senha
  forgotPassword(obj: any) {
    return this.http.post<any>(`${this.apiURL}/users/forgotPassword`, obj)
  }

  changePassword(obj: any) {
    return this.http.post<any>(`${this.apiURL}/users/changePassword`, obj)
  }

  //---------------------------------------------------------------
  //Alterar Senha Perdida
  changelostPassword(password_code: any, password: any) {
    return this.http.post<any>(`${this.apiURL}/users/changeLostPassword`, { password_code: password_code, password: password })
  }

  //---------------------------------------------------------------
}
