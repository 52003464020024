import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../_service/alert/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../_service/app.service';
import { Title } from '@angular/platform-browser';
import { LoadingService } from '../_service/loading/loading.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private alert: AlertService, private modal: NgbModal, private appService: AppService, private title: Title,
        private loading: LoadingService) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("currentUser");
            this.loading.hide();
            this.appService.getAppInfo().subscribe((response) => {
                localStorage.setItem("app_info", JSON.stringify(response))
                this.router.navigate(['/authentication/signin']);
                this.loading.hide();
                this.modal.dismissAll();
                this.alert.error(err.error.message);
            });
            return of(err.message);
        }
        if (err.status === 500 || err.status === 501 || err.status === 0) {
            this.router.navigate(['/authentication/page500']);
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("currentUser");
            this.loading.hide();
            this.modal.dismissAll();
        }

        return throwError(err);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        if (token != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    "Cache-Control": "no-cache",
                    Pragma: "no-cache"
                }
            });
        }

        return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
    }
}