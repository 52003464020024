import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiURL } from '../_helper/url';
import { GetCurrencyByMethod, GetPaymentMethodsByCompanyResponse } from './_interface/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  apiURL: any = localStorage.getItem("urlData"); //URL DA API

  constructor(private http: HttpClient) { }

  //---------------------------------------------------------------
  //Obter Empresas - Publico
  getCompanyPublic() {
    return this.http.get<any>(`${this.apiURL}/company/everyone/public`)
  }

  //---------------------------------------------------------------
  //Obter Empresas
  getCompany(type: "PAYER" | "HEAD_OFFICE" | "BRANCH_OFFICE" = "PAYER") {
    return this.http.get<any>(`${this.apiURL}/company`, {
      params: {
        type: type
      }
    })
  }


  getPayersByCountry(idCountry: number) {
    return this.http.get<any>(`${this.apiURL}/company/country/${idCountry}`);
  }

  getMethodsByCountry(idCountry: number,idCurrency: number) {
    return this.http.get<any>(`${this.apiURL}/company/delivery/method/${idCountry}/${idCurrency}`);
  }  

  getPayersByCoin(id: number, id_country: any, id_delivery_method: any) {
    return this.http.get<any>(`${this.apiURL}/company/currency/${id}/${id_country}/${id_delivery_method}`);
  }

  getCoinsByCountry(idCountry: number) {
    return this.http.get<any>(`${this.apiURL}/exchange/currencies/country/${idCountry}`);
  }

  getAuthorizedsByPayers(id: number, id_method: any) {
    return this.http.get<any>(`${this.apiURL}/subsidiary/company/${id}/${id_method}`);
  }

  getMarketsByAuthorizeds(id_company: number, id_currency: number, id_delivery_method: number) {
    return this.http.get<any>(`${this.apiURL}/company/payment/point/${id_company}/${id_currency}/${id_delivery_method}`);
  }

  //---------------------------------------------------------------
  //Obter Unica Empresa
  getUniqueCompany(id: number) {
    return this.http.get<any>(`${this.apiURL}/company/` + id)
  }

  // Get payment methods by company
  getPaymentMethods(companyId: number): Observable<GetPaymentMethodsByCompanyResponse> {
    return this.http.get<GetPaymentMethodsByCompanyResponse>(`${this.apiURL}/exchange/deliveryMethodPayingCompany/${companyId}`)
  }

  // Get currency by method payment
  getCurrency(methodId: number): Observable<GetCurrencyByMethod> {
    return this.http.get<GetCurrencyByMethod>(`${this.apiURL}/exchange/currencyMethodPayingCompany/${methodId}`)
  }


  // Get banks for Company
  getBanksForCompany(idCountry: number): Observable<any> {
    return this.http.get<any>(`${this.apiURL}/banks/${idCountry}`);
  }

  // Get banks for coutrny
  getBanksForCountry(iso: number): Observable<any> {
    return this.http.get<any>(`${this.apiURL}/banks/payers/${iso}`);
  }

  // Get banks for coutrny
  getBanksForCountryInvoice(iso: number): Observable<any> {
    return this.http.get<any>(`${this.apiURL}/banks/country/${iso}`);
  }

  //---------------------------------------------------------------
  //Criar Empresa
  create(form: FormGroup) {
    const formData = new FormData();
    formData.append('name', form.value.name);
    formData.append('document', form.value.document);
    formData.append('email', form.value.email);
    formData.append('telephone', form.value.telephone);
    formData.append('zipcode', form.value.zipcode);
    formData.append('address', form.value.address);
    formData.append('opening_date', form.value.opening_date);
    formData.append('id_city', form.value.id_city);
    formData.append('type', form.value.type);
    formData.append('id_company', form.value.id_company);
    formData.append('omnex_code', form.value.omnex_code);
    form.value.logo.forEach(logo => formData.append('logo', logo, logo.name));
    return this.http.post<any>(`${this.apiURL}/company`, formData)
  }

  //---------------------------------------------------------------
  //Editar Empresa
  update(form: FormGroup, id: number) {
    const formData = new FormData();
    formData.append('name', form.value.name);
    formData.append('document', form.value.document);
    formData.append('email', form.value.email);
    formData.append('telephone', form.value.telephone);
    formData.append('zipcode', form.value.zipcode);
    formData.append('address', form.value.address);
    formData.append('status', form.value.status);
    formData.append('opening_date', form.value.opening_date);
    formData.append('id_city', form.value.id_city);
    formData.append('id_company', form.value.id_company);
    formData.append('omnex_code', form.value.omnex_code);
    if (form.value.logo) form.value.logo.forEach(logo => formData.append('logo', logo, logo.name));
    return this.http.patch<any>(`${this.apiURL}/company/` + id, formData)
  }

  //---------------------------------------------------------------
  //Remover Empresa
  delete(id: number) {
    return this.http.delete<any>(`${this.apiURL}/company/` + id)
  }


  getListCurrency() {
    return this.http.get<any>(`${this.apiURL}/exchange/listCurrencies/10/1`)
  }

  getInvoiceSummary() {
    return this.http.get<any>(`${this.apiURL}/invoice/totalOfWeek/10/1`)
  }

  createCredit(form: any) {
    return this.http.post<any>(`${this.apiURL}/company/addCredit`, form)
  }

  getTypes() {
    return this.http.get<any>(`${this.apiURL}/company/typesOfCompany`)
  }
  //---------------------------------------------------------------
}
