import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../_service/alert/alert.service';
import { InvoiceService } from '../_service/invoice.service';
import { LoadingService } from '../_service/loading/loading.service';

@Component({
  selector: 'app-receipt-out',
  templateUrl: './receipt-out.component.html',
  styleUrls: ['./receipt-out.component.scss']
})
export class ReceiptOutComponent implements OnInit {


  // ============================ VARIABLE AREA ============================ //
  
  // Data vars
  idInvoice: any; 
  invoiceData: any;
  user: any
  // Data vars end
  
  // Control vars
  // Control vars end
  
  // Form vars
  // Form vars end
  
  // Loading vars
  // Loading vars end
  
  // ============================ END OF VARIABLE AREA ============================ //
  
  
  // ============================ NATIVE FUNCTIONS AREA ============================ //
    // Initial build function
    constructor(
      private route: ActivatedRoute,
      private invoiceService: InvoiceService,
      private loading: LoadingService,
      private alert: AlertService
    ) {
      this.idInvoice = this.route.snapshot.paramMap.get('idInvoice');
    }
  
    // Component's initial function
    ngOnInit(): void {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.loading.show("Carregando dados da remessa...");
      this.invoiceService.getUniqueInvoice(Number(this.idInvoice)).subscribe((response) => {
        if(response.success){
          this.loading.hide();
          this.invoiceData = response.data;
          setTimeout(() => {
            this.onPrint();
          }, 1500)     
        }else{
          this.loading.hide();
          this.alert.warning(response.message)
        }
      }, (err: HttpErrorResponse) => {
        this.loading.hide();
        this.alert.error(err.message);
      })
    }
  
    // ============================ END OF NATIVE FUNCTIONS AREA ============================ //
  
  
  // ============================ FUNCTIONS AREA ============================ //
  
  //Brief description of the function
  onPrint(){
    const printContents = document.getElementById("data").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
      
  }
  
  // ============================ END OF FUNCTIONS AREA ============================ //

}
