import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_service/alert/alert.service';
import { ClientService } from 'src/app/_service/client.service';
import { CompanyService } from 'src/app/_service/company.service';
import { CountryService } from 'src/app/_service/country.service';
import { LoadingService } from 'src/app/_service/loading/loading.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-bank',
  templateUrl: './new-bank.component.html',
  styleUrls: ['./new-bank.component.scss']
})
export class NewBankComponent implements OnInit {
  public formNewAccount: FormGroup;
  public banksForCountryList: any;
  public fullCountryList: any;
  @Input() public id_user;
  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    public loading: LoadingService,
    private companyService: CompanyService,
    private alert: AlertService,
    public countryService: CountryService,
    private clientService: ClientService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initialForm();
    this.getFullCountries();
  }

  public initialForm() {
    this.formNewAccount = this.fb.group({
      id_user: [null, Validators.required],
      id_bank: [null, Validators.required],
      iso: [null, Validators.required],
      account_number: [null, Validators.required],
      account_type: [null, Validators.required],
      account_type_name: [null],
    });
  }

  createAccount() {
    this.formNewAccount.patchValue({
      id_user: Number(this.id_user)
    });
    if (this.formNewAccount.valid) {
      this.loading.show("Criando conta...");
      this.clientService.createAccount(this.formNewAccount.value).subscribe(response => {
        if (response.success) {
          this.loading.hide();
          this.alert.success(response.message)
          this.formNewAccount.reset();
          this.closeModal(true);
        } else {
          this.loading.hide();
          this.alert.warning(response.message)
        }
      }, err => {
        this.alert.error('Tente novamente mais tarde!');
      });
    } else {
      this.alert.error('Preencha todos os dados!');
    }
  }


  public getBanksForCountry(isoCountry: number) {
    this.loading.show("Carregando bancos...");
    this.companyService.getBanksForCountryInvoice(isoCountry).subscribe((response) => {
      if (response.success) {
        this.loading.hide();
        this.banksForCountryList = response.data;
      } else {
        this.loading.hide();
        this.alert.warning(response.message);
      }
    }, err => {
      this.alert.error('Tente novamente mais tarde!');
    });
  }


  public getFullCountries() {
    this.countryService.getCountry().subscribe(res => {
      if (res.success) {
        this.fullCountryList = res.data;
      }
    });
  }


  closeModal(update: boolean) {
    this.activeModal.close(update);
  }

}
