import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { apiURL } from '../_helper/url';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  apiURL: any = localStorage.getItem("urlData"); //URL DA API

  constructor(private http:HttpClient) { }

  //---------------------------------------------------------------
  //Obter Países
  getCountry(){
    return this.http.get<any>(`${this.apiURL}/country`)
  }

  //---------------------------------------------------------------
  //Obter Países por Empresa
  getCountrysContentCompany(){
    return this.http.get<any>(`${this.apiURL}/country/company-exists`)
  }

  //---------------------------------------------------------------
}
