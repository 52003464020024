<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cadastar Conta Bancária</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form class="validate-form" [formGroup]="formNewAccount">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>País</mat-label>
                    <mat-select formControlName="iso" matNativeControl
                        (selectionChange)="getBanksForCountry($event.value)" required>
                        <mat-option *ngFor="let item of fullCountryList" [value]="item.iso">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Bancos</mat-label>
                    <mat-select formControlName="id_bank" matNativeControl required>
                        <mat-option *ngFor="let item of banksForCountryList"
                            [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Número da conta:</mat-label>
                    <input matInput formControlName="account_number" />
                </mat-form-field>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Tipo de conta:</mat-label>
                    <mat-select formControlName="account_type" matNativeControl required>
                        <mat-option value="CC">Conta Corrente</mat-option>
                        <mat-option value="CP">Conta Poupança</mat-option>
                        <mat-option value="OTHER">Outro</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2"
                *ngIf="formNewAccount.value.account_type == 'OTHER'">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Tipo:</mat-label>
                    <input matInput formControlName="account_type_name" />
                </mat-form-field>
            </div>


            <div class="container-auth-form-btn">
                <div style="text-align:center">
                    <button mat-raised-button color="primary" class="auth-form-btn" (click)="createAccount()"
                        type="submit">Adicionar</button>
                </div>
            </div>

        </div>
    </form>
</div>